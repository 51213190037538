<template>
  <nav id="mobile-nav" class="mobile-nav">
    <div class="mmenu-container" id="mm-1">
      <ul class="mmenu-listview">
        <li
          v-for="(categ, index) in categories"
          :key="index"
          class="menu-item"
          :class="{ active: categ.slug === topParentSlug }"
        >
          <router-link :to="{ name: 'ProductList', params: { slug: categ.slug } }">
            <img v-if="categ.icon" :src="categ.icon" :alt="categ.name" />

            <!-- <img v-else
              :src="iconSrc()"
              :alt="categ.name"
            /> -->

            <span class="">{{ categ.name }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>

export default {
  props: ["categories"],
  computed: {
    topParentSlug() {
      // console.log(this.$store.getters["shop/topParentSlug"]);
      return this.$store.getters["shop/topParentSlug"];
    },
  },
  methods: {
    iconSrc() {
      const categ_id_two = 2;
      // console.log(this.categories);
      return require(`@/assets/images/mmenu/categ_${categ_id_two}.png`);
    },
  },
  mounted() {
    // console.log(this.currentCateg);
  },
};
</script>

<style scoped>
.mobile-nav .mmenu-listview img {
  width: 45px;
  margin-right: 20px;
}

.menu-item {
  font-family: Raleway Medium;
  background-color: #fff;
  height: 44px;
  font-size: 12px;
  border-top: 1px solid rgba(205, 203, 203, 0.22);
  padding-right: 10px;
}

.menu-item:nth-child(1) {
  border-top: none;
}

.mmenu-listview,
.mmenu-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.mobile-nav .mmenu-listview > li > a,
.mmenu-listview > li > span {
  color: #000;
  padding: 5px 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin: 0;
  font-size: 13px;
}

.sticky-menu-categs {
  width: 340px;
  position: absolute;
  margin-top: -5px;
  border-radius: 24px;
  box-shadow: 0 0 8px 8px rgba(156, 156, 156, 0.5);
  clip-path: inset(0px -15px -15px -15px);
}

.sticky-menu-categs .mmenu-listview > li {
  height: 52px;
  cursor: pointer;
}

.sticky-menu-categs .mmenu-listview > li:last-of-type {
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.sticky-menu-categs .mmenu-listview > li > a {
  display: flex;
  align-items: center;
  height: 100%;
}

/* .sticky-menu-categs .mmenu-listview img {
  order: 1;
  margin-left: auto;
  display: none;
} */

/* .sticky-menu-categs .mmenu-listview > li > span {
  order: 0;
} */

.sticky-menu-categs .mmenu-listview > li:hover,
.sticky-menu-categs .mmenu-listview > li.active {
  background-color: #efefef;
}

/* .sticky-menu-categs .mmenu-listview > li:hover img {
  display: block;
} */

</style>
